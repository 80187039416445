import { useTranslation } from "react-i18next";
import { Offert } from "../../components/offert/offert";
import loyalityProgramme from "../../assets/images/loyality-programme/loyality-programme.png";
import advert from "../../assets/images/advert/advert.png";
import { HowItWorks } from "../../components/how-it-works/how-it-works";
import "./loyality-program.css"
import awards from "../../assets/images/loyality-programme/awards.png";
import services from "../../assets/images/loyality-programme/services.png";
import visitPlaces from '../../assets/images/offert1/gra.gif';


export const LoyalityProgram = () => {
    const { t, } = useTranslation();

    const steps = [
        {
            "id": 1,
            "imgSrc": visitPlaces,
            "headingKey": "loyalityProgramme.howItWorks.step1.header",
            "textKey": "loyalityProgramme.howItWorks.step1.description"
        },
        {
            "id": 2,
            "imgSrc": awards,
            "headingKey": "loyalityProgramme.howItWorks.step2.header",
            "textKey": "loyalityProgramme.howItWorks.step2.description"
        },
        {
            "id": 3,
            "imgSrc": services,
            "headingKey": "loyalityProgramme.howItWorks.step3.header",
            "textKey": "loyalityProgramme.howItWorks.step3.description"
        }
    ];

    return (
        <div className="loyality-program">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <Offert
                            heading={t('loyalityProgramme.header')}
                            paragraph={t('loyalityProgramme.description')}
                            image={loyalityProgramme}
                            buttonText={t('stepsCTA')}
                            buttonCta=""
                        ></Offert>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <HowItWorks
                            header="loyalityProgramme.howItWorks.header"
                            steps={steps}
                        ></HowItWorks>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <Offert
                            heading={t('advert.header')}
                            paragraph={t('advert.description')}
                            image={advert}
                        ></Offert>
                    </div>
                </div>
            </div>
        </div>
    );
};