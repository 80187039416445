import { useTranslation } from "react-i18next";
import { Offert } from "../../components/offert/offert";
import { HowItWorks } from "../../components/how-it-works/how-it-works";
import { LoyalityProgram } from "../../pages/loyality-program/loyality-program"
import "./for-companies.css"
import createGame from "../../assets/images/offert1/panel.png";
import visitPlaces from "../../assets/images/offert1/gra.gif";
import ranking from "../../assets/images/offert1/ranking.png";
import panel from "../../assets/panel.png";
import { Screenshots } from "../../components/screenshots/screenshots";
import Footer from "../../components/footer/footer";

export const ForCompanies = () => {
    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language;

    const steps = [
        {
            "id": 1,
            "imgSrc": createGame,
            "headingKey": "step1heading",
            "textKey": "step1"
        },
        {
            "id": 2,
            "imgSrc": visitPlaces,
            "headingKey": "step2heading",
            "textKey": "step2"
        },
        {
            "id": 3,
            "imgSrc": ranking,
            "headingKey": "step3heading",
            "textKey": "step3"
        }
    ];

    return (
        <div className="light-green-background">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <Offert
                            heading={t('steps')}
                            paragraph={t('stepsDescription')}
                            image={panel}
                            buttonText={t('stepsCTA')}
                            buttonCta=""
                        ></Offert>
                    </div>
                </div>
            </div>


            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <HowItWorks
                            header="howItWorks"
                            steps={steps}
                        ></HowItWorks>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h2 dangerouslySetInnerHTML={{ __html: t('offertCarouselHeading') }}></h2>

                        <div className="pricing">
                            <div className="price-box">
                                <div>
                                    <p>
                                        <b>BASIC</b><br></br>
                                        {t('offert1price')}
                                    </p>

                                </div>
                                <p dangerouslySetInnerHTML={{ __html: t('offert1') }} />
                            </div>

                            <div className="price-box">
                                <div className="blue-background">
                                    <p>
                                        <b>ADVANCED</b><br></br>
                                        {t('offert2price')}
                                    </p>
                                </div>
                                <p dangerouslySetInnerHTML={{ __html: t('offert2') }} />
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <LoyalityProgram></LoyalityProgram>

            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <Footer></Footer>
                    </div>
                </div>
            </div>
        </div>
    );
};