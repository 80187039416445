import { useTranslation } from "react-i18next";
import Footer from "../../../components/footer/footer";
import React from "react";
import { useNavigate } from "react-router-dom";
import "./shop-thank-you-page.css"


export const ShopThankYouPage = () => {
    const { t, } = useTranslation();
    const navigate = useNavigate();

    return (
        <div className="data-component">

            <div className="container">
                <div className="row">
                    <div className="col-12 thank-you-page">
                        <h1>{t('shop.confirmation.header')}</h1>
                        <p>{t('shop.confirmation.info')}</p>
                        <div className="actions">
                            <button onClick={() => navigate("/")}>
                                {t('shop.confirmation.homepage')}
                            </button>
                            <button onClick={() => navigate("/shop")}>
                                {t('shop.confirmation.continueShopping')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>


            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <Footer></Footer>
                    </div>
                </div>
            </div>
        </div>
    );
};