import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box"
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import "./order.css"
import Grid2 from "@mui/material/Grid2";
import Footer from "../../components/footer/footer";
import axios from "axios";
import { useTranslation } from "react-i18next";

function Order() {
    const { t } = useTranslation();
    const { uuid } = useParams();
    const [orderData, setOrderData] = useState({
        productId: uuid,
        quantity: 1,
        email: "",
        hasApp: false,
        marketingAgreement: false,
        digitalServiceApproval: false,
        acceptRulesAndPrivacyPolicy: false,
        acceptRulesAndPrivacyPolicyP24: false,
        promoCode: "",
    });
    const [product, setProduct] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const apiUrl = process.env.REACT_APP_API_URL;
    const apiKey = process.env.REACT_APP_API_KEY;
    const appEnv = process.env.REACT_APP_ENV;

    useEffect(() => {
        setOrderData((prevData) => ({
            ...prevData,
            productId: uuid,
        }));

        const fetchProduct = async () => {
            try {
                const response = await axios.get(`${apiUrl}/shop/${uuid}/`, {
                    headers: {
                        'x-api-key': apiKey,
                    },
                });
                setProduct(response.data);
            } catch (err) {
                setError('Failed to fetch product data');
            } finally {
                setLoading(false);
            }
        };

        if (!product) {
            fetchProduct();
        }
    }, []);

    const changeQuantity = (amount) => {
        setOrderData((prevState) => ({
            ...prevState,
            quantity: Math.max(1, prevState.quantity + amount),
        }));
    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;

        setOrderData((prevData) => ({
            ...prevData,
            [name]: type === "checkbox" ? checked : value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!orderData.email) {
            setError(t('shop.validation.email'));
            return;
        }

        if (!orderData.digitalServiceApproval || !orderData.acceptRulesAndPrivacyPolicy || !orderData.acceptRulesAndPrivacyPolicyP24) {
            setError(t('shop.order.requiredFields'));
            return;
        }

        setLoading(true);
        setError(null);

        try {
            if (appEnv === "sandbox") {
                alert("To jest wersja testowa. Zamówienie nie zostanie faktycznie złożone.");
                setLoading(false);
                return;
            }

            const response = await axios.post(`${apiUrl}/shop/pay-and-order/`, {
                product_id: orderData.productId,
                quantity: orderData.quantity,
                email: orderData.email,
                marketing_consent: orderData.marketingConsent,
                has_app: orderData.hasApp,
                accept_rules_and_privacy_policy: orderData.acceptRulesAndPrivacyPolicy,
                accept_rules_and_privacy_policy_P24: orderData.acceptRulesAndPrivacyPolicyP24,
            }, {
                headers: {
                    'x-api-key': apiKey,
                }
            });

            if (response.data.payment_url) {
                // Redirect user to the payment URL
                window.location.href = response.data.payment_url;
            }
        } catch (error) {
            setError(error.response?.data?.error || "Something went wrong");
        } finally {
            setLoading(false);
        }
    };

    const handleApplyPromoCode = () => {
        // Logika do obsługi kodu promocyjnego
        alert(`Kod promocyjny ${orderData.promoCode} nie jest aktywny!`);
    };

    const calculatePrice = () => {
        if (!product || !product.price || orderData.quantity <= 0) {
            return 0;
        }
        const totalPrice = parseFloat(product.price) * orderData.quantity;
        return parseFloat(totalPrice.toFixed(2));
    };

    if (loading) return <p>Loading...</p>;

    return (
        <>
            <div className="light-green-background">
                <div className="container">
                    <div className="two-columns">

                        <div className="order-form">
                            <h1>{t('shop.order.header')}</h1>
                            {error && <div style={{ color: "red" }}>{error}</div>}
                            <Box>
                                <form>
                                    <TextField
                                        label="Email"
                                        type="email"
                                        variant="outlined"
                                        fullWidth
                                        required
                                        id="email"
                                        name="email"
                                        value={orderData.email}
                                        onChange={handleChange}
                                        helperText={t('shop.order.emailHelperText')}
                                        margin="normal"
                                    />

                                    {/* <FormControlLabel
                control={
                    <Checkbox
                        name="hasApp"
                        checked={orderData.hasApp}
                        onChange={handleChange}
                        color="primary"
                    />
                }
                label={t('shop.checkboxes.hasApp')}
            /> */}

                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                required
                                                name="digitalServiceApproval"
                                                checked={orderData.digitalServiceApproval}
                                                onChange={handleChange}
                                                color="primary"
                                            />
                                        }
                                        label={t('shop.checkboxes.digitalServiceApproval')}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                required
                                                name="acceptRulesAndPrivacyPolicy"
                                                checked={orderData.acceptRulesAndPrivacyPolicy}
                                                onChange={handleChange}
                                                color="primary"
                                            />
                                        }
                                        label={<p
                                            dangerouslySetInnerHTML={{
                                                __html: t('shop.checkboxes.acceptRulesAndPrivacyPolicy'),
                                            }}
                                        />}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                required
                                                name="acceptRulesAndPrivacyPolicyP24"
                                                checked={orderData.acceptRulesAndPrivacyPolicyP24}
                                                onChange={handleChange}
                                                color="primary"
                                            />
                                        }
                                        label={<p
                                            dangerouslySetInnerHTML={{
                                                __html: t("shop.checkboxes.p24agreement"),
                                            }}
                                        />}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name="marketingConsent"
                                                checked={orderData.marketingConsent}
                                                onChange={handleChange}
                                                color="primary"
                                            />
                                        }
                                        label={t('shop.checkboxes.marketingAgreement')}
                                    />
                                    <Grid2 container spacing={1} marginTop={2}>
                                        <Grid2 item xs>
                                            <TextField
                                                id="promoCode"
                                                name="promoCode"
                                                label={t('shop.order.promoCode')}
                                                variant="outlined"
                                                fullWidth
                                                value={orderData.promoCode}
                                                onChange={handleChange}
                                            />
                                        </Grid2>
                                        <Grid2 item>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={handleApplyPromoCode}
                                            >
                                                {t('shop.order.apply')}
                                            </Button>
                                        </Grid2>
                                    </Grid2>

                                    {product && (
                                        <div className="product">
                                            <div>
                                                <h3>{product.name}</h3>
                                                <span>{product.price} zł</span>
                                            </div>
                                            <div className="product-amount">
                                                <i
                                                    className="fa-solid fa-square-minus"
                                                    onClick={() => changeQuantity(-1)}
                                                    style={{
                                                        color: orderData.quantity === 1 ? "lightgrey" : "inherit",
                                                        pointerEvents: orderData.quantity === 1 ? "none" : "auto",
                                                    }}
                                                ></i>
                                                <span>{orderData.quantity}</span>
                                                <i
                                                    className="fa-solid fa-square-plus"
                                                    onClick={() => changeQuantity(1)}
                                                ></i>
                                            </div>
                                        </div>
                                    )}
                                    {/* <p>Wybierz metodę płatności</p>
            <div className="payment-method">
                <i className="fa-solid fa-money-bill-transfer"></i>
                <img src={p24}></img>
            </div> */}

                                    <p className="agreement">{t('shop.order.requiredFields')}</p>
                                </form>
                            </Box>
                        </div>
                        {product && (
                            <div className="order-summary">
                                <img src={`${apiUrl}${product.photo}`} alt="Produkt"></img>
                                <div className="order-summary-confirmation">
                                    <h3>{t('shop.order.summary')}</h3>
                                    <div className="order-item">
                                        <p>{orderData.quantity} x {product.name}</p><p>{product.price} zł</p>
                                    </div>
                                    <div className="order-total">
                                        <p>{t('shop.order.total')}</p><p>{calculatePrice()} zł</p>
                                    </div>
                                </div>
                                <button onClick={handleSubmit} className="button-pay">
                                    {t('shop.order.payAndOrder')}
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="m-4"></div>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <Footer></Footer>
                    </div>
                </div>
            </div>

        </>
    );
}

export default Order;