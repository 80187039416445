import { useTranslation } from "react-i18next";
import "./start.css"
import mainScreen from '../../assets/images/mockup.png';
import portal from '../../assets/images/portal.jpeg';
import trawellLogo from '../../assets/images/trawell_logo.svg';
import googlePlay from '../../assets/google_play.png';
import apple from '../../assets/apple.svg';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import 'swiper/css/autoplay';
import 'swiper/css';
import { Card } from "../../components/card/card";

import warszawa from '../../assets/images/cities/warszawa.jpeg';
import krakow from '../../assets/images/cities/krakow.png';
import gdansk from '../../assets/images/cities/gdansk.jpeg';
import praga from '../../assets/images/cities/praga.jpeg';
import paris from '../../assets/images/cities/paris.jpeg';
import wroclaw from '../../assets/images/cities/wroclaw.jpeg';
import bari from '../../assets/images/cities/bari.jpeg';

import { Screenshots } from "../../components/screenshots/screenshots";
import Footer from "../../components/footer/footer";

import KITS from '../../assets/images/success/KITS.png';
import cassini from '../../assets/images/success/cassini.svg';
import sun from '../../assets/images/success/sun.png';
import kpt from '../../assets/images/success/kpt.svg';
import kms from '../../assets/images/success/kms.png';
import Creators from "../../components/creators/creators";


export const Start = () => {
    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language;


    return (
        <>
            <div className="light-green-background">
                <div className="container">
                    <div className="row">
                        <div className="col-7 center-verticaly d-xs-none">
                            <h1>{t("appTagline")}</h1>
                            <h2>{t("app")}</h2>

                            <img id="app-logo" src={trawellLogo} alt="Trawell"></img>
                            <p dangerouslySetInnerHTML={{ __html: t("appDescription") }}>
                            </p>
                        </div>
                        <div className="col-5 center-verticaly center-horizontaly p-1 position-relative">
                            <div className="rectangle rect1"></div>
                            <div className="rectangle rect2"></div>
                            <div className="rectangle rect3"></div>
                            <div className="rectangle rect4"></div>
                            <div className="rectangle rect5"></div>
                            <div className="rectangle rect6"></div>
                            
                            <img id="mobile-app-mockup" src={mainScreen} alt="mobile app"></img>
                            <Card
                                        image={portal}
                                        name={t('demo.name')}
                                        description={t('demo.titbit')}
                                        inApp={true}
                                    ></Card>
                        </div>
                        <div className="col-12 download-app-link-box">
                            <div className="container">
                                <div className="row">
                                    <div className="download-text d-xs-none col-7"
                                        dangerouslySetInnerHTML={{ __html: t("downloadText") }}
                                    >
                                    </div>
                                    <div className="download-buttons col-5">
                                        <a href="https://apps.apple.com/pl/app/trawell/id6450898028">
                                            <img
                                                className="apple"
                                                src={apple}
                                                alt="Download on the App Store"></img>
                                        </a>
                                        <a href='https://play.google.com/store/apps/details?id=com.allventures.trawell'>
                                            <img
                                                className="android"
                                                alt='Get it on Google Play'
                                                src={googlePlay}></img>
                                        </a>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="row">
                        <h1 className="col-12 m-4-auto">
                            {t('discoverCities')}
                        </h1>
                        <div className="col-12">
                            <Swiper
                                slidesPerView="auto" // Automatyczna liczba slajdów w zależności od szerokości
                                spaceBetween={40} // Stała odległość między kartami
                                modules={[Autoplay]}
                                autoplay={{
                                    delay: 1500,
                                    pauseOnMouseEnter: true,
                                    disableOnInteraction: false
                                }}
                                loop
                            >

                                <SwiperSlide>
                                    <Card
                                        image={krakow}
                                        name={t('krakow.name')}
                                        description={t('krakow.titbit')}
                                    ></Card>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <Card
                                        image={warszawa}
                                        name={t('warszawa.name')}
                                        description={t('warszawa.titbit')}
                                    ></Card>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <Card
                                        image={gdansk}
                                        name={t('gdansk.name')}
                                        description={t('gdansk.titbit')}
                                    ></Card>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <Card
                                        image={praga}
                                        name={t('prague.name')}
                                        description={t('prague.titbit')}
                                    ></Card>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <Card
                                        image={paris}
                                        name={t('paris.name')}
                                        description={t('paris.titbit')}
                                    ></Card>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <Card
                                        image={wroclaw}
                                        name={t('wroclaw.name')}
                                        description={t('wroclaw.titbit')}
                                    ></Card>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <Card
                                        image={bari}
                                        name={t('bari.name')}
                                        description={t('bari.titbit')}
                                    ></Card>
                                </SwiperSlide>
                            </Swiper>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container"></div>

            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <Screenshots language={currentLanguage} />
                    </div>
                </div>
            </div>

            <div id="success" className="container">
                <h1 className="center m-3">{t("ourSuccess")}</h1>
                <div>
                    <Swiper
                        slidesPerView="auto" // Automatyczna liczba slajdów w zależności od szerokości
                        spaceBetween={100} // Stała odległość między kartami
                        modules={[Autoplay]}
                        autoplay={{
                            delay: 1500,
                            pauseOnMouseEnter: true,
                            disableOnInteraction: false
                        }}
                        loop
                    >
                        <SwiperSlide>
                            <img src={cassini} alt="Cassini"></img>
                            <p>{t("cassiniWinner")}</p>
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={kpt} alt="KPT"></img>
                            <p>KPT Pitch Fighting Club</p>
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={sun} alt="SUN Hubs Netowrk Incubator"></img>
                            <p>SUN Hubs Netowrk Incubator</p>
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={KITS} alt="KITS"></img>
                            <p>KrK Innotech Starter</p>
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={kms} alt="KMS"></img>
                            <p>{t("audienceWinner")}</p>
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={kms} alt="KMS"></img>
                            <p>{t("juryWinner")}</p>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>


            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h1 className="center m-3">{t("creators")}</h1>
                        <Creators></Creators>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <Footer></Footer>
                    </div>
                </div>
            </div>

        </>
    );
};