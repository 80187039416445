import { useTranslation } from "react-i18next";
import { Offert } from "../../components/offert/offert";
import { HowItWorks } from "../../components/how-it-works/how-it-works";
import "./internship.css"
import team from "../../assets/images/internship/team.JPG";
import marketing from "../../assets/images/internship/marketing.JPG";
import uxui from "../../assets/images/internship/uxui.png";
import mobile from "../../assets/images/internship/mobile.jpeg";
import ranking from '../../assets/images/offert1/ranking.png';
import contentCreator from '../../assets/images/internship/content-creator.png';
import backend from '../../assets/images/internship/backend.png';
import business from '../../assets/images/internship/business.jpeg';
import translator from '../../assets/images/internship/translator.png';
import webDevReact from '../../assets/images/internship/web-dev-react.png';
import Footer from "../../components/footer/footer";

export const Internship = () => {
    const { t } = useTranslation();
    const steps = [
        {
            "imgSrc": marketing,
            "headingKey": "internship.marketing.name",
            "textKey": "internship.marketing.description"
        },
        {
            "imgSrc": mobile,
            "headingKey": "internship.mobile-dev.name",
            "textKey": "internship.mobile-dev.description"
        },
        {
            "imgSrc": webDevReact,
            "headingKey": "internship.web-dev-react.name",
            "textKey": "internship.web-dev-react.description"
        },
        {
            "imgSrc": ranking,
            "headingKey": "internship.web-dev-angular.name",
            "textKey": "internship.web-dev-angular.description"
        },
        {
            "imgSrc": backend,
            "headingKey": "internship.backend-dev.name",
            "textKey": "internship.backend-dev.description"
        },
        {
            "imgSrc": translator,
            "headingKey": "internship.translator.name",
            "textKey": "internship.translator.description"
        },
        {
            "imgSrc": business,
            "headingKey": "internship.business-dev.name",
            "textKey": "internship.business-dev.description"
        },
        {
            "imgSrc": uxui,
            "headingKey": "internship.ux-ui.name",
            "textKey": "internship.ux-ui.description"
        },
        {
            "imgSrc": contentCreator,
            "headingKey": "internship.content-creator.name",
            "textKey": "internship.content-creator.description"
        }
    ];

    return (
        <div className="light-green-background">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <Offert
                            heading={t('internship.header')}
                            paragraph={t('internship.description')}
                            image={team}
                            buttonText=""
                            buttonCta=""
                        ></Offert>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <HowItWorks
                            header="internship.positions-header"
                            steps={steps}
                        ></HowItWorks>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row">
                    <div className="col-12">
                    <Footer></Footer>
                    </div>
                </div>
            </div>
        </div>
    );
};