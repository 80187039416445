import { useTranslation } from "react-i18next";
import './menu.css';
import React, { useState, useEffect, useRef } from 'react';
import createdBy from '../../assets/images/created_by.png';
import { Link } from 'react-router-dom';
import LanguagePicker from './../language-picker/language-picker';

export const Menu = () => {
    const { i18n, t } = useTranslation();

    const [menuVisible, setMenuVisible] = useState(false);
    const menuRef = useRef(null);

    const onChangeLang = (lang_code) => {
        i18n.changeLanguage(lang_code);
    };

    // Efekt do nasłuchiwania kliknięć poza menu
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setMenuVisible(false); 
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [menuRef]);

    return (
        <div className="menu" ref={menuRef}>
            <div className="container">
                <div className="row">
                    <div className="col-12 flex-row-center-space-between">
                        <a href="/" className="logo"><img src={createdBy} alt="Allventures" /></a>
        
                        <div className="mobile-menu-container">
                            <Link to="/shop" className="d-sm-only" onClick={() => setMenuVisible(!menuVisible)}><i className="fa fa-cart-shopping"></i></Link>
                            <i className="fa-solid fa-bars hamburger-icon" onClick={() => setMenuVisible(!menuVisible)}></i>
                        </div>

                        <div className={`menu-links ${menuVisible ? 'visible' : 'hidden'}`}>
                            <Link to="/for-companies" onClick={() => setMenuVisible(!menuVisible)}>{t('menu.company')}</Link>
                            <Link to="/data" onClick={() => setMenuVisible(!menuVisible)}>{t('menu.data')}</Link>
                            <Link to="/internship" onClick={() => setMenuVisible(!menuVisible)}>{t('menu.internship')}</Link>
                            <Link to="/about" onClick={() => setMenuVisible(!menuVisible)}>{t('menu.about')}</Link>
                            <Link to="/contact" onClick={() => setMenuVisible(!menuVisible)}>{t('menu.contact')}</Link>
                            <Link to="/shop" className="d-sm-none d-md-block" onClick={() => setMenuVisible(!menuVisible)}><i className="fa fa-cart-shopping"></i></Link>
                            <LanguagePicker onChangeLang={onChangeLang} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};