import './offert.css';


export const Offert = ({ heading, paragraph, image, buttonText, buttonCta }) => {

  return (
    <>
      <h2 dangerouslySetInnerHTML={{ __html: heading }}></h2>
      <section id="offert">
          <p dangerouslySetInnerHTML={{ __html: paragraph }} />
          <div>
            <img src={image}></img>
            <center>
                { buttonText && <button className="button button-blue button-mail">{buttonText}</button> }
            </center>
          </div>
      </section>
    </>
  );
};
