import React, { useState } from 'react';
import './language-picker.css';
import { LANGUAGES } from "../../constants/index";

const LanguagePicker = ({onChangeLang}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState(LANGUAGES[0]);

    const handleSelect = (language) => {
        setSelectedLanguage(language);
        onChangeLang(language.code);
        setIsOpen(false);
    };

    return (
        <div className="language-picker">
            <div
                className="selected-option"
                onClick={() => setIsOpen(!isOpen)}
            >
                <img className="flag" src={selectedLanguage.flag} alt={selectedLanguage.code}></img>
                <span className="language-name">{selectedLanguage.label}</span>                  
                <span className={`arrow ${isOpen ? 'up' : 'down'}`}>▾</span>
            </div>

            {isOpen && (
                <ul className="options-list">
                    {LANGUAGES.map((language) => (
                        <li
                            key={language.code}
                            className={`option ${language.code === selectedLanguage.code ? 'selected' : ''
                                }`}
                            onClick={() => handleSelect(language)}
                        >
                            <img className="flag" src={language.flag} alt={language.code}></img>
                            <span className="language-name">{language.label}</span>            
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default LanguagePicker;