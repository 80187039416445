import { useTranslation } from "react-i18next";
import Creators from "../../components/creators/creators";
import Footer from "../../components/footer/footer";

import "./about.css"


export const About = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="light-green-background">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1>{t("creators")}</h1>
              <Creators></Creators>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1>{t("story")}</h1>
            <div className='m-2'></div>
            <iframe title="spotify" style={{ borderRadius: '12px' }} src="https://open.spotify.com/embed/episode/3NEAlRLigBzexcQNI8aIma?utm_source=generator" width="100%" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <Footer></Footer>
          </div>
        </div>
      </div>
    </>

  );
};