import { useTranslation } from "react-i18next";
import { Offert } from "../../components/offert/offert";
import Footer from "../../components/footer/footer";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import shop_cover from '../../assets/images/shop/shop.png';
import axios from "axios";
import "./shop.css";

export const Shop = () => {
    const { t, i18n } = useTranslation();
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const apiUrl = process.env.REACT_APP_API_URL;
    const apiKey = process.env.REACT_APP_API_KEY;
    const currentLanguage = i18n.language;

    useEffect(() => {
        const fetchProduct = async () => {
            try {
                const response = await axios.get(`${apiUrl}/shop/`, {
                    headers: {
                        'x-api-key': apiKey,
                    },
                });
                setProducts(response.data);
            } catch (err) {
                setError('Failed to fetch products');
            } finally {
                setLoading(false);
            }
        };

        fetchProduct();
    }, []);

    const formatDate = (date) => {
        return new Intl.DateTimeFormat(currentLanguage, {
            year: "numeric",
            month: "long",
            day: "numeric",
        }).format(new Date(date));
    };

    const getAvailabilityPeriod = (availableFrom, availableTo) => {
        const now = new Date();
        const fromDate = new Date(availableFrom);
        const toDate = new Date(availableTo);

        if (toDate < now) {
            return t("shop.product.availability.unavailable", { date: formatDate(toDate) });
        }

        if (fromDate < now) {
            return t("shop.product.availability.availableUntil", { date: formatDate(toDate) });
        }

        return t("shop.product.availability.availableFromTo", {
            fromDate: formatDate(fromDate),
            toDate: formatDate(toDate)
        });
    };

    return (
        <div className="light-green-background">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <Offert
                            heading={t('shop.header')}
                            paragraph={t('shop.description')}
                            image={shop_cover}
                            buttonText=""
                            buttonCta=""
                        ></Offert>
                    </div>
                </div>
            </div>
            
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        {error && <div style={{ color: "red" }}>{error}</div>}
                        {loading && <div>{loading}</div>}

                        {products.map((product, index) => (
                            <div className="product-container">
                                <div className="product-image">
                                    <img src={`${apiUrl}${product.photo}`} alt={product.name}></img>
                                </div>
                                <div className="product-description">
                                    <h2 className="product-title right">{product.name}</h2>
                                    <div className="product-data right">{getAvailabilityPeriod(product.available_from, product.available_to)}</div>
                                    <p className="product-text" dangerouslySetInnerHTML={{ __html: product.description }} />

                                    <Link
                                        to={`/order/${product.uuid}`}
                                        className="product-price right">
                                        <i className="fa-solid fa-cart-shopping"></i>
                                        {product.old_price && <s>{product.old_price} zł</s>}
                                        {product.price} zł
                                    </Link>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>


            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <Footer></Footer>
                    </div>
                </div>
            </div>
        </div >
    );
};