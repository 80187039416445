import './App.css';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Menu } from "./components/menu/menu"
import { ScrollToTop } from "./components/scroll-to-top/scroll-to-top"
import { Data } from "./pages/data/data"
import { Home } from "./pages/home/home"
import { Start } from "./pages/start/start"
import { ForCompanies } from "./pages/for-companies/for-companies"
import { Internship } from "./pages/internship/internship"
import { Shop } from "./pages/shop/shop"
import { ShopThankYouPage } from "./pages/shop/thank-you-page/shop-thank-you-page"
import Order from './pages/order/order';
import { Contact } from './pages/contact/contact';
import { About } from './pages/about/about';

function App() {
  return (
    <BrowserRouter>
        <Menu />
        <ScrollToTop />
        <Routes className='container'>
          <Route path="/" element={<Start />} />
          <Route path="/data" element={<Data />} />
          <Route path="/for-companies" element={<ForCompanies />} />
          <Route path="/internship" element={<Internship />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/about" element={<About />} />
          <Route path="/shop" element={<Shop />} />
          <Route path="/shop/thank-you" element={<ShopThankYouPage />} />
          <Route path="/order/:uuid" element={<Order />} />
        </Routes>
    </BrowserRouter>
  );
}

export default App;
