import "./footer.css"
import tikTok from '../../assets/images/svg/tiktok.svg';

function Footer() {


    return (
        <section id="footer">
            <p className="col-4">
                Allventures sp. z o. o., ul. Wielicka 42 / B3, 30-552 Kraków<br></br>
                KRS: 0001034424<br></br>
                NIP: 6793266456<br></br>
                REGON: 525230529
            </p>
            <div className="col-4 social-media">
                <a href="https://www.instagram.com/trawell_allventures" target="_blank">
                    <i className="fa-brands fa-square-instagram"></i>
                    <span>Instagram</span>
                </a>
                <a href="https://www.facebook.com/people/Trawell/100092505494503/" target="_blank">
                    <i className="fa-brands fa-square-facebook" alt="Trawell facebook"></i>
                    <span>Facebook</span>
                </a>
                <a href="https://www.tiktok.com/@trawell_allventures" target="_blank">
                    {/* <i className="fa-brands fa-tiktok"></i> */}
                    <img src={tikTok} style={{height: '2rem', padding: '0.15rem'}}></img>
                    <span>TikTok</span>
                </a>
                <a href="https://www.linkedin.com/company/86671615" target="_blank">
                    <i className="fa-brands fa-linkedin"></i>
                    <span>Linkedin</span>
                </a>
            </div>
            <ul className="col-4">
                <li><a href="/regulamin_sklepu.pdf" target="_blank">Regulamin</a></li>
                <li><a href="/polityka_prywatnosci_sklepu.pdf" target="_blank">Polityka prywatności</a></li>
            </ul>
        </section>
    );
}

export default Footer;