import { useEffect } from 'react';
import "./formModal.css"
import ReCAPTCHA from 'react-google-recaptcha';

export const FormModal = ({
    captchaValue, 
    setCaptchaValue, 
    setFormSubmitted, 
    handleSubmit, 
    message,
    setMessage
    }) => {

    useEffect(() => {
        if(captchaValue) {
            handleSubmit();
        }
    }, [captchaValue]); 

    const handleFormModal = (captchaValue) => {
        setCaptchaValue(captchaValue);
    }

    return (
        <div className='form-modal'>
            <div id="close" onClick={() => {setFormSubmitted(false); setMessage("")}}>
            <i className="fa fa-close"></i>

            </div>
            { message ? <p>{message}</p> : <ReCAPTCHA
            sitekey={"6LeBugEpAAAAADWxBDusMnpEKqP8Z2lqTVGoc47H"}
            onChange={(captchaValue) => handleFormModal(captchaValue)}
            /> }
        </div>

    );
};