import './screenshots.css';
import cards from '../../assets/screenshots/ENG/cards.png';
import categories from '../../assets/screenshots/ENG/categories.png';
import challenges from '../../assets/screenshots/ENG/challenges.png';
import hints from '../../assets/screenshots/ENG/hints.png';
import history from '../../assets/screenshots/ENG/history.png';
import navigation from '../../assets/screenshots/ENG/navigation.png';
import points from '../..//assets/screenshots/ENG/points.png';
import ranking from '../..//assets/screenshots/ENG/ranking.png';
import sightseeing from '../..//assets/screenshots/ENG/sightseeing.png';
import trivia from '../..//assets/screenshots/ENG/trivia.png';

import cardsPL from '../../assets/screenshots/PL/cards.png';
import categoriesPL from '../../assets/screenshots/PL/categories.png';
import challengesPL from '../../assets/screenshots/PL/challenges.png';
import hintsPL from '../../assets/screenshots/PL/trivia.png';
import historyPL from '../../assets/screenshots/PL/history.png';
import navigationPL from '../../assets/screenshots/PL/navigation.png';
import pointsPL from '../..//assets/screenshots/PL/points.png';
import rankingPL from '../..//assets/screenshots/PL/ranking.png';
import sightseeingPL from '../..//assets/screenshots/PL/sightseeing.png';
import triviaPL from '../..//assets/screenshots/PL/trivia.png';

import {Autoplay} from "swiper/modules";
import {Swiper, SwiperSlide} from "swiper/react";

const images = {
    "pl-PL": [
        {name: 'cards', src: cardsPL},
        {name: 'categories', src: categoriesPL},
        {name: 'challenges', src: challengesPL},
        {name: 'hints', src: hintsPL},
        {name: 'history', src: historyPL},
        {name: 'navigation', src: navigationPL},
        {name: 'points', src: pointsPL},
        {name: 'ranking', src: rankingPL},
        {name: 'sightseeing', src: sightseeingPL},
        {name: 'trivia', src: triviaPL},
    ],
    "en": [
        {name: 'cards', src: cards},
        {name: 'categories', src: categories},
        {name: 'challenges', src: challenges},
        {name: 'hints', src: hints},
        {name: 'history', src: history},
        {name: 'navigation', src: navigation},
        {name: 'points', src: points},
        {name: 'ranking', src: ranking},
        {name: 'sightseeing', src: sightseeing},
        {name: 'trivia', src: trivia},
    ],
    fr: []
}

export const Screenshots = ({language = 'en'}) => {

    return (
        language in images &&
        <Swiper
            slidesPerView="auto" // Automatyczna liczba slajdów w zależności od szerokości
            spaceBetween={40} // Stała odległość między kartami
            modules={[Autoplay]}
            autoplay={{
                delay: 2000,
                pauseOnMouseEnter: true,
                disableOnInteraction: false
            }}
            loop
            className="screenshots-gallery"
        >
            {images[language].map((image) => (
                <SwiperSlide key={image.name}>
                    <img className="app-screenshot" key={image.name} src={image.src} alt={image.name}/>
                </SwiperSlide>
            ))}
        </Swiper>
    );
};