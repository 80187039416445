import "./contact.css";
import Footer from "../../components/footer/footer";
import React, { useState } from 'react';
import axios from 'axios';
import { useTranslation } from "react-i18next";
import { FormModal } from '../../components/formModal/formModal';

const SERVICE_ID = "service_65jjnoq";
const TEMPLATE_ID = "template_j7u0zwt";
const PUBLIC_KEY = "YFfxiHiLRuZ0n1Lw5";

export const Contact = () => {
    const { t } = useTranslation();
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [captchaValue, setCaptchaValue] = useState(null);
    const [form, setForm] = useState({ from_name: "", from_email: "", message: "" });
    const [message, setMessage] = useState("");
    const [errors, setErrors] = useState({});

    const handleForm = (e) => {
        const { name, value } = e.target;
        setForm({ ...form, [name]: value });
    };

    const validateForm = () => {
        let newErrors = {};
        if (!form.from_name.trim()) newErrors.from_name = t("contactForm.nameRequired");
        if (!form.from_email.trim()) newErrors.from_email = t("contactForm.emailRequired");
        if (!form.message.trim()) newErrors.message = t("contactForm.messageRequired");
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const validateOnSubmit = () => {
        if (!validateForm()) return;

        setFormSubmitted(true);
    }

    const handleSubmit = async (e) => {
        const data = {
            service_id: SERVICE_ID,
            template_id: TEMPLATE_ID,
            user_id: PUBLIC_KEY,
            template_params: {
                ...form,
                'to_name': 'Allventures',
                'g-recaptcha-response': captchaValue
            },
        };

        try {
            const response = await axios.post("https://api.emailjs.com/api/v1.0/email/send", data);
            setCaptchaValue(null);
            setForm({ from_name: "", from_email: "", message: "" });
            setErrors({});
            if (response.status === 200) {
                setMessage(t("contactForm.contactFormSend"));
            }
        } catch (error) {
            setMessage(t("contactForm.contactFormError"));
        }
    };

    return (
        <div className="light-green-background">
            <div className="container">
                <div className="row">
                    <div className="col-6 col-xs-12 m-2">
                        <p>{t("contact.questions")}</p>
                        <h1>{t("contact.contactWithUs")}</h1>
                        <p>{t("contact.fillForm")}</p>
                        <div className="email-field">
                            <i className="fa-solid fa-envelope"></i>
                            management@allventures.eu
                        </div>
                        <h2>{t("contact.response")}</h2>
                    </div>
                    <div className="col-6 col-xs-12 contact-section">
                        {formSubmitted && <FormModal
                            setFormSubmitted={setFormSubmitted}
                            captchaValue={captchaValue}
                            setCaptchaValue={setCaptchaValue}
                            handleSubmit={handleSubmit}
                            message={message}
                            setMessage={setMessage}
                        />}
                        <form onSubmit={(e) => { e.preventDefault(); validateOnSubmit() }}>
                            <label>{t("contactForm.name")}</label>
                            <input type="text" name="from_name" value={form.from_name} onChange={handleForm} />
                            {errors.from_name && <p className="error-text">{errors.from_name}</p>}

                            <label>{t("contactForm.email")}</label>
                            <input type="email" name="from_email" value={form.from_email} onChange={handleForm} />
                            {errors.from_email && <p className="error-text">{errors.from_email}</p>}

                            <label>{t("contactForm.message")}</label>
                            <textarea rows="10" name="message" value={form.message} onChange={handleForm}></textarea>
                            {errors.message && <p className="error-text">{errors.message}</p>}

                            <button className='button mt-1' type="submit">{t("contactForm.send")}</button>
                        </form>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <Footer />
                    </div>
                </div>
            </div>
        </div>
    );
};
