import "./creators.css"
import team from '../../assets/team_allventures_2.jpeg';
import adrian from '../../assets/adrian.jpg';
import michal from '../../assets/michal.jpg';
import piotr from '../../assets/piotr.png';
import jacek from '../../assets/jacek.jpg';
import ania from '../../assets/ania.png';
import { useTranslation } from "react-i18next";

function Creators() {
    const { t } = useTranslation();

    return (
        <div className='about m-2'>
            <div className='members'>
                <div className='member team'>
                    <img id="team-photo" src={team} alt="team" />
                </div>
                <p style={{ width: "200px" }}>
                    {t("about1")}
                </p>
                <div className='member'>
                    <img id="team-photo" src={adrian} alt="Adrian"/>
                </div>
                <div className='member'>
                    <img id="team-photo" src={michal} alt="Michal" />
                </div>
                <div className='member'>
                    <img id="team-photo" src={piotr} alt="Piotr" />
                </div>
                <div className='member'>
                    <img id="team-photo" src={jacek} alt="Jacek"/>
                </div>
                <div className='member'>
                    <img id="team-photo" src={ania} alt="Ania" />
                </div>
                <p style={{ width: "200px" }}>
                    {t("about2")}
                </p>
            </div>
        </div>
    );
}

export default Creators;