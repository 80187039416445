import './card.css';


export const Card = ({ image, name, description, inApp = false }) => {

  return (
    <>
      <div className={`app-container ${inApp ? "in-app" : ""}`}>

        <div className="app-card">
          <img src={image} alt={name}></img>
          <p>{name}</p>
        </div>
        <div className="app-card-back">
          {description ?? description}
        </div>
      </div>

    </>
  );
};